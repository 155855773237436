import React from "react";

import { AnchorHTMLAttributes } from "react";
import { DetailedHTMLProps } from "react";

import clsx from "clsx";

import * as classNames from "@css/component/LinkButton.module.scss";

/**
 * @type AnchorButtonProps
 */
export type AnchorButtonProps = DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
    variant?: string;
};

/**
 * @const AnchorButton
 */
const AnchorButton = (props: AnchorButtonProps) => {
    const {
        className,
        variant,
        ...restProps
    } = props;

    return (
        <a { ...restProps } className={ clsx(classNames.linkButton, {
            [classNames.white]: variant == "white"
        }, className) } />
    );
};

export default AnchorButton;
