import React from "react";

import { SVGProps } from "react";

/**
 * @type PhoneLaptopIconProps
 */
export type PhoneLaptopIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const PhoneLaptopIcon
 */
const PhoneLaptopIcon = (props: PhoneLaptopIconProps) => {
    return (
        <svg { ...props } aria-hidden="true" data-prefix="far" role="img" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M112 48h352v48h48V32a32.09 32.09 0 0 0-32-32H96a32.09 32.09 0 0 0-32 32v256H16a16 16 0 0 0-16 16v16a64.14 64.14 0 0 0 63.91 64H352v-96H112zm492 80H420a36 36 0 0 0-36 36v312a36 36 0 0 0 36 36h184a36 36 0 0 0 36-36V164a36 36 0 0 0-36-36zm-12 336H432V176h160z" fill="currentColor" />
        </svg>
    );
};

export default PhoneLaptopIcon;
