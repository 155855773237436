import React from "react";

import { SVGProps } from "react";

/**
 * @type PhoneWhatsAppIconProps
 */
export type PhoneWhatsAppIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const PhoneWhatsAppIcon
 */
const PhoneWhatsAppIcon = (props: PhoneWhatsAppIconProps) => {
    return (
        <svg { ...props } xmlns="http://www.w3.org/2000/svg" width="29.119" height="16.979" viewBox="0 0 29.119 16.979">
            <g transform="translate(21399 22687)">
                <path d="M27.673,0H16V16.979H27.673Zm-6.9,14.326h2.653v1.061H20.245V14.326Z" transform="translate(-21415 -22687)" fill="currentColor"/>
                <path d="M11.611,33.984A6.769,6.769,0,0,0,.96,42.151L0,45.656l3.588-.942a6.746,6.746,0,0,0,3.234.823h0a6.724,6.724,0,0,0,4.786-11.553ZM6.825,44.4a5.615,5.615,0,0,1-2.865-.783l-.2-.122-2.128.558.567-2.076-.134-.213a5.636,5.636,0,1,1,10.453-2.99A5.687,5.687,0,0,1,6.825,44.4ZM9.91,40.185c-.168-.085-1-.494-1.155-.549s-.268-.085-.381.085-.436.549-.536.665-.2.128-.366.043a4.6,4.6,0,0,1-2.3-2.012c-.174-.3.174-.277.5-.924a.313.313,0,0,0-.015-.3c-.043-.085-.381-.918-.521-1.256s-.277-.284-.381-.29-.21-.006-.323-.006a.626.626,0,0,0-.451.21,1.9,1.9,0,0,0-.591,1.411,3.313,3.313,0,0,0,.689,1.75,7.556,7.556,0,0,0,2.89,2.554A3.309,3.309,0,0,0,8.992,42a1.732,1.732,0,0,0,1.14-.8,1.414,1.414,0,0,0,.1-.8C10.19,40.31,10.078,40.267,9.91,40.185Z" transform="translate(-21383.537 -22719)" fill="currentColor"/>
            </g>
        </svg>
    );
};

export default PhoneWhatsAppIcon;
