/**
 * @const SET_DELIVERY_TIME
 * @const SET_METHODOLOGY
 * @const SET_ORDER_BY
 * @const SET_PRICE_MAX
 * @const SET_PRICE_MIN
 * @const SET_PRICE_TIER_MAX
 * @const SET_PRICE_TIER_MIN
 * @const SET_REVIEW_AVERAGE
 */
export const SET_DELIVERY_TIME = "Filter.SetDeliveryTime";
export const SET_METHODOLOGY = "Filter.SetMethodology";
export const SET_ORDER_BY = "Filter.SetOrderBy";
export const SET_PRICE_MAX = "Filter.SetPriceMax";
export const SET_PRICE_MIN = "Filter.SetPriceMin";
export const SET_PRICE_TIER_MAX = "Filter.SetPriceTierMax";
export const SET_PRICE_TIER_MIN = "Filter.SetPriceTierMin";
export const SET_REVIEW_AVERAGE = "Filter.SetReviewAverage";
