import React from "react";

import { SVGProps } from "react";

/**
 * @type EnvelopeIconProps
 */
export type EnvelopeIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const EnvelopeIcon
 */
const EnvelopeIcon = (props: EnvelopeIconProps) => {
    return (
        <svg { ...props } aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" width="16.667" height="12.5" viewBox="0 0 16.667 12.5">
            <path d="M0,64H16.667v2.6L8.333,72.333,0,66.6ZM0,76.5V67.867l7.744,5.326.589.4.589-.407,7.744-5.322V76.5Z" transform="translate(0 -64)" fill="currentColor"/>
        </svg>
    );
};

export default EnvelopeIcon;
