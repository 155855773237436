import { AnyAction } from "redux";

import StoreState from "@js/store/StoreState";

import { SET_SECURITY } from "@js/actionTypes/loginActionTypes";

import { SetSecurityAction } from "@js/actions/loginActions";

/**
 * @type SecurityState
 */
type SecurityState = StoreState["security"];

/**
 * @const securityReducer
 */
const securityReducer = (state: SecurityState, action: AnyAction) => {
    const securityAction = action as SetSecurityAction;

    switch (securityAction.type) {
        case SET_SECURITY: {
            return { ...securityAction.payload };
        }

        default: {
            return { ...state };
        }
    }
};

export default securityReducer;
