import React from "react";

import { SVGProps } from "react";

/**
 * @type ThumbsUpIconProps
 */
export type ThumbsUpIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const ThumbsUpIcon
 */
const ThumbsUpIcon = (props: ThumbsUpIconProps) => {
    return (
        <svg { ...props } aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" width="16.499" height="14.437" viewBox="0 0 16.499 14.437">
            <path d="M7.734,33.15V32h3.094v1.15A7.723,7.723,0,0,1,10,36.64h6.5v3.094h-.516v2.578H15.21v2.32h-.773v1.8H8.556l-.39-.261-2.32-1.547-.69-.458V37.443l.58-.464.255-.206a4.633,4.633,0,0,0,1.74-3.622ZM4.125,37.156v9.281H0V37.156Z" transform="translate(0 -32)" fill="currentColor"/>
        </svg>
    );
};

export default ThumbsUpIcon;
