import React from "react";

import { Provider } from "react-redux";
import { ReactNode } from "react";
import store from "@js/store/Store";

/**
 * @type RootWrapperProps
 */
export type RootWrapperProps = {
    element: ReactNode;
};

/**
 * @const RootWrapper
 */
const RootWrapper = (props: RootWrapperProps) => {
    const {
        element
    } = props;

    return (
        <Provider store={ store }>
            { element }
        </Provider>
    );
};

export default RootWrapper;
