import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";

import clsx from "clsx";

import { createPortal } from "react-dom";

import * as classNames from "@css/component/Backdrop.module.scss";

/**
 * @type BackdropProps
 */
export type BackdropProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children">;

/**
 * @const Backdrop
 */
const Backdrop = (props: BackdropProps) => {
    const {
        className,
        ...restProps
    } = props;

    const children = (
        <div { ...restProps } className={ clsx(classNames.backdrop, className) } />
    );

    return createPortal(children, document.body);
};

export default Backdrop;
