import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";

import clsx from "clsx";

import StarIcon from "@js/component/icon/StarIcon";

import * as classNames from "@css/component/Stars.module.scss";

/**
 * @type StarsProps
 */
export type StarsProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    backgroundColor: string;
    foregroundColor: string;
    foregroundPercentage: number;
};

/**
 * @const Stars
 */
const Stars = (props: StarsProps) => {
    const {
        backgroundColor,
        children,
        className,
        foregroundColor,
        foregroundPercentage,
        ...restProps
    } = props;

    const backgroundPercentage = 100 - foregroundPercentage;

    return (
        <div { ...restProps } className={ clsx(classNames.stars, className) }>
            <div className={ classNames.background } style={{ "width": `${ backgroundPercentage }%` }}>
                <StarIcon className={ classNames.star } style={{ "color": backgroundColor }} />
                <StarIcon className={ classNames.star } style={{ "color": backgroundColor }} />
                <StarIcon className={ classNames.star } style={{ "color": backgroundColor }} />
                <StarIcon className={ classNames.star } style={{ "color": backgroundColor }} />
                <StarIcon className={ classNames.star } style={{ "color": backgroundColor }} />
            </div>
            <div className={ classNames.foreground } style={{ "width": `${ foregroundPercentage }%` }}>
                <StarIcon className={ classNames.star } style={{ "color": foregroundColor }} />
                <StarIcon className={ classNames.star } style={{ "color": foregroundColor }} />
                <StarIcon className={ classNames.star } style={{ "color": foregroundColor }} />
                <StarIcon className={ classNames.star } style={{ "color": foregroundColor }} />
                <StarIcon className={ classNames.star } style={{ "color": foregroundColor }} />
            </div>
            { children }
        </div>
    );
};

export default Stars;
