import {useEffect, useState} from "react";

/**
 * useHandleResize
 */
const useHandleResize = () => {
    const [ isMobileView, setIsMobileView ] = useState(false);

    const handleResize = () => {
        if (window.innerWidth >= 1200) {
            setIsMobileView(false);
        } else {
            setIsMobileView(true);
        }
    }

    useEffect( () => {
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    return isMobileView;
};

export default useHandleResize;