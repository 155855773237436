import React from "react";

import { SVGProps } from "react";

/**
 * @type BarsIconProps
 */
export type BarsIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const BarsIcon
 */
const BarsIcon = (props: BarsIconProps) => {
    return (
        <svg { ...props } aria-hidden="true" data-prefix="far" role="img" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z" fill="currentColor" />
        </svg>
    );
};

export default BarsIcon;
