import React from "react";

import { ReactNode } from "react";

import Layout from "@js/component/Layout";

/**
 * @type PageWrapperProps
 */
export type PageWrapperProps = {
    element: ReactNode;
};

/**
 * @const PageWrapper
 */
const PageWrapper = (props: PageWrapperProps) => {
    const {
        element
    } = props;

    return (
        <Layout>{ element }</Layout>
    );
};

export default PageWrapper;
