import { AnyAction } from "redux";

import StoreState from "@js/store/StoreState";

import { SET_DELIVERY_TIME } from "@js/actionTypes/filterActionTypes";
import { SET_METHODOLOGY } from "@js/actionTypes/filterActionTypes";
import { SET_ORDER_BY } from "@js/actionTypes/filterActionTypes";
import { SET_PRICE_MAX } from "@js/actionTypes/filterActionTypes";
import { SET_PRICE_MIN } from "@js/actionTypes/filterActionTypes";
import { SET_PRICE_TIER_MAX } from "@js/actionTypes/filterActionTypes";
import { SET_PRICE_TIER_MIN } from "@js/actionTypes/filterActionTypes";
import { SET_REVIEW_AVERAGE } from "@js/actionTypes/filterActionTypes";

import { AnyFilterAction } from "@js/actions/filterActions";

/**
 * @type FilterState
 */
type FilterState = StoreState["filter"];

/**
 * @const filterReducer
 */
const filterReducer = (state: FilterState, action: AnyAction) => {
    const filterAction = action as AnyFilterAction;

    switch (filterAction.type) {
        case SET_DELIVERY_TIME: {
            return { ...state, deliveryTime: filterAction.payload.deliveryTime };
        }

        case SET_METHODOLOGY: {
            return { ...state, methodology: filterAction.payload.methodology };
        }

        case SET_ORDER_BY: {
            return { ...state, orderBy: filterAction.payload.orderBy };
        }

        case SET_PRICE_MAX: {
            return { ...state, priceMax: filterAction.payload.priceMax };
        }

        case SET_PRICE_MIN: {
            return { ...state, priceMin: filterAction.payload.priceMin };
        }

        case SET_PRICE_TIER_MAX: {
            return { ...state, priceTierMax: filterAction.payload.priceTierMax };
        }

        case SET_PRICE_TIER_MIN: {
            return { ...state, priceTierMin: filterAction.payload.priceTierMin };
        }

        case SET_REVIEW_AVERAGE: {
            return { ...state, reviewAverage: filterAction.payload.reviewAverage };
        }

        default: {
            return { ...state };
        }
    }
};

export default filterReducer;
