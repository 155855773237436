import { AnyAction } from "redux";

import StoreState from "@js/store/StoreState";

import { SET_OPEN, SET_PROFILE_COMPLETE, SET_HAS_PARTNERSHIPS } from "@js/actionTypes/statusActionTypes";

import { AnyStatusAction } from "@js/actions/statusActions";

/**
 * @type StatusState
 */
type StatusState = StoreState["status"];

/**
 * @const statusReducer
 */
const statusReducer = (state: StatusState, action: AnyAction) => {
    const statusAction = action as AnyStatusAction;

    switch (statusAction.type) {
        case SET_OPEN: {
            return { ...state, open: statusAction.payload.open };
        }

        case SET_PROFILE_COMPLETE: {
            return { ...state, profileComplete: statusAction.payload.profileComplete };
        }

        case SET_HAS_PARTNERSHIPS: {
            return { ...state, hasPartnerships: statusAction.payload.hasPartnerships };
        }

        default: {
            return { ...state };
        }
    }
};

export default statusReducer;
