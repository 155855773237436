import React from "react";

import { SVGProps } from "react";

/**
 * @type RegisterIconProps
 */
export type RegisterIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const RegisterIcon
 */
const RegisterIcon = (props: RegisterIconProps) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="14.305" height="12.517" viewBox="0 0 14.305 12.517">
            <path d="M5.364,33.788l4.47,4.47-4.47,4.47H4.47V40.047H0V36.47H4.47V33.788Zm4.47,8.941h2.682V33.788H8.941V32h5.364V44.517H8.941V42.729Z" transform="translate(0 -32)" fill="currentColor"/>
        </svg>

    );
};

export default RegisterIcon;
