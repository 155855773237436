import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";

import TopBar from "@js/component/TopBar";
import TopMenu from "@js/component/TopMenu";

import clsx from "clsx";

import * as classNames from "@css/component/SiteHeader.module.scss";

/**
 * @type SiteHeaderProps
 */
export type SiteHeaderProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children">;

/**
 * @const SiteHeader
 */
const SiteHeader = (props: SiteHeaderProps) => {
    const {
        className,
        ...restProps
    } = props;

    return (
        <header { ...restProps } className={ clsx(classNames.siteHeader, className) }>
            <TopBar />
            <TopMenu />
            { /* <UserMenu /> */ }
        </header>
    );
};

export default SiteHeader;
