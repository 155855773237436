import React from "react";

import { SVGProps } from "react";

/**
 * @type StarIconProps
 */
export type StarIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const StarIcon
 */
const StarIcon = (props: StarIconProps) => {
    return (
        <svg { ...props } aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
            <path opacity="1" fill="currentColor" d="M288.1 0l63.5 195.6H557.2L390.9 316.4 454.4 512 288.1 391.1 121.7 512l63.5-195.6L18.9 195.6H224.5L288.1 0z"/>
        </svg>
    );
};

export default StarIcon;
