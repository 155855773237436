import React from "react";

import { SVGProps } from "react";

/**
 * @type UserSingleIconProps
 */
export type UserSingleIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const UserSingleIcon
 */
const UserSingleIcon = (props: UserSingleIconProps) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="11.275" height="12.885" viewBox="0 0 11.275 12.885">
            <path d="M5.637,6.443A3.221,3.221,0,1,0,2.416,3.221,3.221,3.221,0,0,0,5.637,6.443Zm5.637,6.443L9.664,7.651H1.611L0,12.885Z" fill="currentColor"/>
        </svg>
    );
};

export default UserSingleIcon;
