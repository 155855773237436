import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";

import clsx from "clsx";

import * as classNames from "@css/component/Heading.module.scss";

/**
 * @type HeadingProps
 */
export type HeadingProps = DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
    element?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    variant?: "extraSmall" | "small" | "medium" | "large" | "extraLarge";
};

/**
 * @const Heading
 */
const Heading = (props: HeadingProps) => {
    const {
        className,
        element,
        variant,
        ...restProps
    } = props;

    const Element = element || "h1";

    return (
        <Element { ...restProps } className={ clsx(classNames.heading, {
            [classNames.extraSmall]: variant == "extraSmall",
            [classNames.small]: variant == "small",
            [classNames.medium]: variant == "medium",
            [classNames.large]: variant == "large",
            [classNames.extraLarge]: variant == "extraLarge"
        }, className) } />
    );
};

export default Heading;
