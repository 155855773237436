import React from "react";

import { SVGProps } from "react";

/**
 * @type ThListIconProps
 */
export type ThListIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const ThListIcon
 */
const ThListIcon = (props: ThListIconProps) => {
    return (
        <svg { ...props } aria-hidden="true" data-prefix="far" role="img" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48H48C21.49 32 0 53.49 0 80zm472 224H197.333v-96H472v96zm0 40v84c0 6.627-5.373 12-12 12H197.333v-96H472zM40 208h117.333v96H40v-96zm157.333-40V72H460c6.627 0 12 5.373 12 12v84H197.333zm-40-96v96H40V84c0-6.627 5.373-12 12-12h105.333zM40 344h117.333v96H52c-6.627 0-12-5.373-12-12v-84z" fill="currentColor" />
        </svg>
    );
};

export default ThListIcon;
