import React from "react";

import { GatsbyLinkProps } from "gatsby";
import { Link } from "gatsby";

import clsx from "clsx";

import * as classNames from "@css/component/LinkButton.module.scss";

/**
 * @type LinkButtonProps
 */
export type LinkButtonProps = Omit<GatsbyLinkProps<{}>, "ref"> & {
    variant?: string;
};

/**
 * @const LinkButton
 */
const LinkButton = (props: LinkButtonProps) => {
    const {
        className,
        variant,
        ...restProps
    } = props;

    return (
        <Link { ...restProps } className={ clsx(classNames.linkButton, {
            [classNames.green]: variant == "green",
            [classNames.white]: variant == "white"
        }, className) } />
    );
};

export default LinkButton;
