import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { Link } from "gatsby";
import { MouseEvent } from "react";

import clsx from "clsx";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { useState } from "react";

import useHandleResize from "@js/hook/useHandleResize";

import Backdrop from "@js/component/Backdrop";
import BarsIcon from "@js/component/icon/BarsIcon";
import ChevronDownIcon from "@js/component/icon/ChevronDownIcon";
import ChevronRightIcon from "@js/component/icon/ChevronRightIcon";
import ChevronUpIcon from "@js/component/icon/ChevronUpIcon";
import Logo from "@js/component/Logo";
import TimesIcon from "@js/component/icon/TimesIcon";

import {useSelector} from "react-redux";
import StoreState from "@js/store/StoreState";
import InfoBalloon from "@js/component/InfoBalloon";
import LinkButton from "@js/component/LinkButton";
import AnchorButton from "@js/component/AnchorButton";
import EnvelopeIcon from "@js/component/icon/EnvelopeIcon";
import PhoneLaptopIcon from "@js/component/icon/PhoneLaptopIcon";

import * as classNames from "@css/component/TopMenu.module.scss";
import CompareIcon from "@js/component/icon/CompareIcon";
import {GatsbyImage, IGatsbyImageData} from "gatsby-plugin-image";

/**
 * @type WordpressMenu
 */
type WordpressMenu = {
    menuItems: {
        nodes: {
            id: string;
            label: string;
            parentId: string;
            url: string;
        }[];
    };
};

/**
 * @type FileData
 */
type FileData = {
    file: {
        childImageSharp: {
            gatsbyImageData: IGatsbyImageData;
        };
    };
};

/**
 * @type WordpressMenuData
 */
type WordpressMenuData = {
    wordpressMenu: WordpressMenu;
};

/**
 * @type Data
 */
type Data = WordpressMenuData & FileData;

/**
 * @type TopMenuProps
 */
export type TopMenuProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children">;

/**
 * @const TopMenu
 */
const TopMenu = (props: TopMenuProps) => {
    const {
        className,
        ...restProps
    } = props;

    const advisorUrl = useSelector((storeState: StoreState) => storeState.information.advisorUrl);
    const hasCompleted = useSelector((storeState: StoreState) => storeState.information.hasCompleted);
    const hasVisited = useSelector((storeState: StoreState) => storeState.information.hasVisited);
    const [activeMenuItems, setActiveMenuItems] = useState<string[]>([]);
    const [isMobileActive, setIsMobileActive] = useState("");
    const [onShowAreaBalloon, setOnShowAreaBalloon] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [isBackdropVisible, setIsBackdropVisible] = useState(false);

    const isMobileView = useHandleResize();

    const data: Data = useStaticQuery(graphql`
        query {
            wordpressMenu(slug: { eq: "header-menu" }) {
                menuItems {
                    nodes {
                        id
                        label
                        parentId
                        url
                    }
                }
            }
            file(relativePath: { eq: "mobile-menu-image.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 300, quality: 100)
                }
            }
        }
    `);

    const onCloseMenuButtonClick = () => {
        setIsOpen(false);
    };

    const onOpenMenuButtonClick = () => {
        setIsOpen(true);
    };

    const onLinkClick = (id: string, parent: string, hasChildren: boolean) => (event: MouseEvent) => {
        if (!parent && hasChildren && isMobileView) {
            setIsMobileActive(id);

            if (!isMobileActive.includes(id)) {
                event.preventDefault();
            } else {
                setIsOpen(false);
            }
        }
        else {
            setIsOpen(false);
        }
    };

    const onMenuItemMouseOut = (id: string, hasChildren: boolean) => {
        if (hasChildren) {
            setIsBackdropVisible(false);

            setActiveMenuItems([]);
        }
    };

    const onMenuItemMouseOver = (id: string, hasChildren: boolean) => {
        if (hasChildren) {
            setIsBackdropVisible(true);

            setActiveMenuItems([id]);
        }
    };

    const menuItemsNodesLevelOne = data.wordpressMenu.menuItems.nodes.filter((menuItemsNodeLevelOne) => {
        return menuItemsNodeLevelOne.parentId == null;
    });

    const activatedOnPage = () => {
        const currentPageUrl = window.location.href;
        return currentPageUrl.includes('vergelijk') || currentPageUrl.includes('adviseurs/') || currentPageUrl.includes('aanvragen/');
    }

    const onMailTo = () => {
        typeof window == "object" && window.gtag && window.gtag("event", "mailto_click");
    };

    return (
        <>
            <div { ...restProps } className={ clsx(classNames.topMenu, className) }>
                <div className={ classNames.container }>
                    <Link aria-label="Logo" className={ classNames.link } to="/">
                        <Logo className={ classNames.logo } />
                    </Link>
                    { isOpen && (
                        <button
                            aria-label="Close menu"
                            className={ classNames.closeButton }
                            onClick={ onCloseMenuButtonClick }
                            type="button"
                        >
                            <TimesIcon className={ classNames.icon } />
                        </button>
                    ) }
                    { !(isOpen) && (
                        <button
                            aria-label="Open menu"
                            className={ classNames.openButton }
                            onClick={ onOpenMenuButtonClick }
                            type="button"
                        >
                            <BarsIcon className={ classNames.icon } />
                            <label>Menu</label>
                        </button>
                    ) }
                    <nav className={ clsx(classNames.navigation, { [classNames.open]: isOpen }) }>
                        { menuItemsNodesLevelOne.length >= 1 && (
                            <ul className={ classNames.list }>
                                { menuItemsNodesLevelOne.map((menuItemsNodeLevelOne, index) => {
                                    const menuItemsNodesLevelTwo = data.wordpressMenu.menuItems.nodes.filter(
                                        (menuItemsNodeLevelTwo) => {
                                            return menuItemsNodeLevelTwo.parentId == menuItemsNodeLevelOne.id;
                                        }
                                    );

                                    return (
                                        <li
                                            className={ classNames.listItem }
                                            key={ menuItemsNodeLevelOne.id }
                                            onMouseLeave={ () => {
                                                    onMenuItemMouseOut(
                                                        menuItemsNodeLevelOne.id,
                                                        menuItemsNodesLevelTwo.length >= 1
                                                    );

                                                    index === 0 && (hasVisited || !hasCompleted && advisorUrl) && setOnShowAreaBalloon(false);
                                                }
                                            }
                                            onMouseEnter={ () => {
                                                    onMenuItemMouseOver(
                                                        menuItemsNodeLevelOne.id,
                                                        menuItemsNodesLevelTwo.length >= 1
                                                    );

                                                    !activatedOnPage() && index === 0 && (hasVisited || !hasCompleted && advisorUrl) && setOnShowAreaBalloon(true);
                                                }
                                            }
                                        >
                                            <Link
                                                activeClassName={ classNames.active }
                                                className={ clsx(classNames.link, { [classNames.listButton]: index === 0 }) }
                                                onClick={
                                                    onLinkClick(
                                                        menuItemsNodeLevelOne.id,
                                                        "",
                                                        menuItemsNodesLevelTwo.length >= 1
                                                    )
                                                }
                                                to={ menuItemsNodeLevelOne.url }
                                            >
                                                { index === 0 &&
                                                    <CompareIcon className={ clsx(classNames.icon, classNames.compare) } />
                                                }
                                                { menuItemsNodeLevelOne.label }

                                                { index === 0 && (hasVisited || !hasCompleted && advisorUrl) && !activatedOnPage() &&
                                                <div className={ classNames.notification } >
                                                    <span>1</span>
                                                </div>
                                                }
                                            </Link>

                                            { index === 0 && onShowAreaBalloon &&
                                            <InfoBalloon
                                                variant="bottom"
                                            >
                                                Even aan het rondneuzen?<br />Kijk rustig verder! Maar vergeet niet je aanvraag af te ronden! Vragen? Neem gerust contact met ons op.<br /><br />

                                                <LinkButton className={ classNames.linkButton } to={ advisorUrl ? `/over-ons/adviseurs/${ advisorUrl }` : '/vergelijk' }>
                                                    <span className={ classNames.text }>Aanvraag afronden</span>
                                                </LinkButton>
                                                <span className={ classNames.linkButtons }>
                                                        <AnchorButton
                                                            className={ classNames.linkButton }
                                                            href="mailto:info@woninglabel.nl"
                                                            onClick={ onMailTo }
                                                        >
                                                            <EnvelopeIcon className={ classNames.icon } />
                                                            <span className={ classNames.text }>Stuur een e-mail</span>
                                                        </AnchorButton>
                                                        <AnchorButton className={ classNames.linkButton } href="tel:0850162080">
                                                            <PhoneLaptopIcon className={ classNames.icon } />
                                                            <span className={ classNames.text }>085 – 01 620 80</span>
                                                        </AnchorButton>
                                                    </span>
                                            </InfoBalloon>
                                            }

                                            { menuItemsNodesLevelTwo.length >= 1 && (
                                                <>
                                                    { activeMenuItems.includes(menuItemsNodeLevelOne.id) &&
                                                    <ChevronUpIcon className={ classNames.icon } />
                                                    }
                                                    { !(activeMenuItems.includes(menuItemsNodeLevelOne.id)) &&
                                                    <ChevronDownIcon className={ classNames.icon } />
                                                    }
                                                    <section className={ clsx(classNames.subMenu, {
                                                        [classNames.open]: activeMenuItems.includes(
                                                            menuItemsNodeLevelOne.id
                                                        ) || isMobileActive.includes(
                                                            menuItemsNodeLevelOne.id
                                                        )
                                                    }) }>
                                                        <div className={ classNames.container }>
                                                            <ul className={ classNames.list }>
                                                                { menuItemsNodesLevelTwo.map((
                                                                    menuItemsNodeLevelTwo
                                                                ) => (
                                                                    <li
                                                                        className={ classNames.listItem }
                                                                        key={ menuItemsNodeLevelTwo.id }
                                                                    >
                                                                        <ChevronRightIcon
                                                                            className={ classNames.icon }
                                                                        />
                                                                        <Link
                                                                            activeClassName={ classNames.active }
                                                                            className={ classNames.link }
                                                                            onClick={ onLinkClick(menuItemsNodeLevelTwo.id, menuItemsNodeLevelOne.id, false) }
                                                                            to={ menuItemsNodeLevelTwo.url }
                                                                        >
                                                                            { menuItemsNodeLevelTwo.label }
                                                                        </Link>
                                                                    </li>
                                                                )) }
                                                            </ul>
                                                        </div>
                                                    </section>
                                                </>
                                            ) }
                                        </li>
                                    );
                                }) }
                                <li className={ classNames.listItem }>
                                    <GatsbyImage
                                        alt="menu image"
                                        className={ classNames.image }
                                        image={ data.file.childImageSharp.gatsbyImageData }
                                    />
                                </li>
                            </ul>
                        ) }
                    </nav>
                </div>
            </div>
            { typeof window == "object" && isBackdropVisible && <Backdrop className={ classNames.backdrop } /> }
        </>
    );
};

export default TopMenu;
