import React from "react";

import { SVGProps } from "react";

/**
 * @type CompareIconProps
 */
export type CompareIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const CompareIcon
 */
const CompareIcon = (props: CompareIconProps) => {
    return (
        <svg { ...props } xmlns="http://www.w3.org/2000/svg" width="15.367" height="15.367" viewBox="0 0 15.367 15.367">
            <path d="M8.644,9.6,5.763,12.486l2.881,2.881H9.6V13.446h.48A3.842,3.842,0,0,0,13.927,9.6V4.8h1.441V0h-4.8V4.8h1.441V9.6a1.923,1.923,0,0,1-1.921,1.921H9.6V9.6Zm3.6-7.924h1.441V3.121H12.246ZM6.723,0h-.96V1.921h-.48A3.842,3.842,0,0,0,1.441,5.763v4.8H0v4.8H4.8v-4.8H3.362v-4.8A1.923,1.923,0,0,1,5.282,3.842h.48V5.763h.96L9.6,2.881ZM1.681,12.246H3.121v1.441H1.681Z" fill="currentColor"/>
        </svg>

);
};

export default CompareIcon;
